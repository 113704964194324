@font-face {
  font-family: 'Helvetica Neue';
  src: url('assets/HelveticaNeue-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('assets/HelveticaNeue-Bold.ttf') format('truetype');
    font-weight: bold;
}

body {
    margin: 0;
    font-family: 'Helvetica Neue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*background-image: url("assets/texture.png");*/
    background-color: #16273f;
}

.textureOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-repeat: no-repeat;
    object-fit: cover;
    background-image: url("assets/texture.png");
    mix-blend-mode: overlay;
    pointer-events: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
    width: 25rem;
    height: 4rem;
    border: solid 3px #fac697;
    font-size: 1.5rem;
    background: #f08374;
    color: white;
    border-radius: 0.8rem;
    text-transform: uppercase;
    font-family: "Helvetica Neue", sans-serif;
}

button:active {
    color: black;
    background: #fac697;
}

.variant {
    background: #FF9B92;
    border-color: #ec6553;
}

.variant:active {
    background: #ec6553;
    color: white;
}

.domanda {
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    color: #fac697;
    font-size: 2rem;
}

.report-block {
    padding: 1rem;
    border-top: 1.5px solid #fff;
    border-bottom: 1.5px solid #fff;
}

.background-icons{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: -1;
}

.background-icon{
    position: absolute;
    width: 7rem;
    object-fit: cover;
}

.icon-1{
    top: 1rem;
    left: -2.3rem;
    width: 8.5rem;
}

.icon-2{
    bottom: 2.5rem;
    left: 4.5rem
}

.icon-3{
    bottom: 2rem;
    width: 10rem;
    right: 2.6rem
}

